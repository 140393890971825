<template>
  <navigation index="sysCompanysManage" ref="navigation">
    <div :style="{ height: ContentHeight + 'px' }">
      <div class="topnavigation">
        <div style="display: flex; gap: 5px">
          <el-button
            style="width: 100px; margin-left: 10px"
            type="primary"
            @click="AddStore()"
          >
            创建
          </el-button>
          <el-input
            v-model="SearchText"
            style="width: 320px"      @keyup.enter="GetCompanyList"
            placeholder="请输入名称，手机号，邮箱进行搜索"
          ></el-input>
          <el-button type="primary" @click="GetCompanyList">
            <el-icon class="no-inherit">
              <Search />
            </el-icon>
            搜索</el-button
          >
        </div>
      </div>

      <el-table
        border
        :data="CompanysDatas"
        style="width: 100%"
        :height="TableHeight + 'px'"
      >
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column label="公司名称" prop="Name" width="220">
        </el-table-column>
        <el-table-column label="邮箱" prop="Mailbox" width="220">
        </el-table-column>
        <el-table-column label="手机号" prop="Phone" width="220">
        </el-table-column>
        <el-table-column label="备注" width="180">
          <template #default="{ row }">
            <div v-html="row.Content"></div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="300" fixed="right">
          <template #default="scope">
            <el-button
              type="success"
              size="small"
              @click="StoreEdit(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button size="small" @click="OpenCompanyUserList(scope.row)"
              >账号管理</el-button
            >

            <el-button
              size="small"
              type="primary"
              @click="OpenCompanysRelevanceStore(scope.$index, scope.row)"
              >店铺管理</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <div style="height: 50px; display: flex; align-items: center">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="CurrentChange"
          :page-size="padding.Size"
          :total="padding.Count"
        >
        </el-pagination>
      </div>
      <!-- 修改新增用户 -->
      <el-dialog
        v-model="EditOrAddWindow"
        :title="IsAdd ? '新增' : '修改'"
        width="60%"
        :before-close="EditOrAddWindowClose"
        :show-close="false"
      >
        <div style="padding: 20px 5px">
          <el-form ref="form" label-width="120px">
            <el-form-item label="公司名称">
              <el-input v-model="CompanysModel.Name"></el-input>
            </el-form-item>
            <el-form-item label="邮箱">
              <el-input v-model="CompanysModel.Mailbox"></el-input>
            </el-form-item>
            <!-- <el-form-item label="密码">
              <el-input v-model="CompanysModel.Password"></el-input>
            </el-form-item> -->
            <el-form-item label="手机号">
              <el-input v-model="CompanysModel.Phone"></el-input>
            </el-form-item>
            <el-form-item label="备注">
              <el-input v-model="CompanysModel.Content"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="EditOrAddWindowClose">关闭</el-button>
            <el-button type="primary" @click="EditOrAddSave()">确定</el-button>
          </span>
        </template>
      </el-dialog>

      <!-- 店铺账号 -->
      <el-dialog
        v-model="OpenCompanysRelevanceStoreWindow"
        title="店铺账号"
        width="60%"
        :show-close="false"
      >
        <div style="margin: 6px 0px">
          <el-button @click="setCurrent(tableData[1])">新增商店</el-button>
          <el-button @click="setCurrent()">取消选择</el-button>
        </div>
        <el-table
          border
          :data="CompanysStoreDatas"
          style="width: 100%"
          :height="height - 270"
        >
          <el-table-column type="index" width="50"> </el-table-column>
          <el-table-column label="用户名称" prop="UserName" width="180">
          </el-table-column>
          <el-table-column label="用户账号" prop="UserAccount" width="180">
          </el-table-column>
          <el-table-column label="操作" fixed="right">
            <template #default="scope">
              <el-button
                type="success"
                size="small"
                @click="CancelRelevadddnceStore(scope.$index, scope.row)"
                >查看</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <template #footer>
          <span class="dialog-footer">
            <el-button @click="OpenCompanysRelevanceStoreWindow = false"
              >关闭</el-button
            >
          </span>
        </template>
      </el-dialog>
    </div>
  </navigation>
</template>
<script >
import axios from "../../../commons/AxiosMethod.js";
import tools from "../../../commons/tools.js";
import { ElMessageBox, ElMessage } from "element-plus";
import { Search, Close, Refresh, Message } from "@element-plus/icons-vue";
import navigation from "../../../components/Modules/AdminNavigation.vue";

import "../../../assets/css/commons.css";
export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      ContentHeight: document.documentElement.clientHeight - 100,
      TableHeight: document.documentElement.clientHeight - 100,

      width: window.innerWidth,
      CompanysStoreDatas: [], //公司包含的商户
      CompanysDatas: [],
      CompanysModel: {}, // 选中公司
      OpenCompanysRelevanceStoreWindow: false,

      EditOrAddWindow: false, // 修改或者新增窗口
      IsAdd: false, // 是否是新增

      AuthorizationModel: {}, //授权账号信息
      SearchText: "", //筛选条件
      padding: {
        Page: 1,
        Size: 30,
        Count: 0,
      },
      CompanysRelevanceStorePadding: {
        Page: 1,
        Size: 30,
        Count: 0,
      },

      StorRelevanceUserWindow: false,

      UploadCertificateUrl: "", //证书上传地址
      fileList: [],
      uploadHeaders: {},

      InvoiceSignatureWindow: false,

      InvoiceSignatureItems: [],

      InvoiceSignatureQrCodeWindow: false, //二维码框
      InvoiceSignatureOpt: {
        TABI: "",
      }, //当前选中二维码

      InvoiceSignaturePadding: {}, //发票签名分页

      ApiUrl: "",

      StartOrderTime: [], //筛选时间区间

      InvoiceWhere: {
        StartTime: "",
        EndTime: "",
        Account: "",
        InvoiceNumber: "",
        TBAI: "",
        SerieFactura: "",
      },
      QrCodeUrl: "",
    };
  },
  name: "App",
  mounted() {
    this.GetCompanyList();
    this.ApiUrl = axios.GetUrl();
    this.UploadCertificateUrl = this.ApiUrl + "/api/Upload/UploadCertificate";
    this.uploadHeaders = {
      Authorization: localStorage.getItem("Authentication_Header"),
    };

    window.onresize = () => {
      //屏幕尺寸变化就重新赋值
      return (() => {
        this.TableHeight = document.documentElement.clientHeight - 120;
        this.ContentHeight = document.documentElement.clientHeight - 200;
        this.$refs.navigation.onresize();
      })();
    };
    this.TableHeight = document.documentElement.clientHeight - 120;
    this.ContentHeight = document.documentElement.clientHeight - 200;
  },
  components: { navigation, Search },
  methods: {
    OpenCompanyUserList(item) {
      this.$router.push({
        path: "companyUserManage",
        query: {
          companyId: item.Id,
        },
      });
    },
    //打开商店用户页面
    OpenCompanysRelevanceStore(index, item) {
      this.$router.push({
        path: "sysStoreManage",
        query: {
          companyId: item.Id,
        },
      });
      return;

      this.CompanysStoreDatas = [];
      this.CompanysRelevanceStorePadding.Where = {
        Id: item.Id,
      };
      axios.apiMethod(
        "/admins/Company/GetStoreByCompanysId",
        "post",
        this.CompanysRelevanceStorePadding,
        (result) => {
          this.CompanysStoreDatas = result.Data.Datas;
          this.CompanysRelevanceStorePadding.Page = result.Data.Page;
          this.CompanysRelevanceStorePadding.Size = result.Data.Size;
          this.CompanysRelevanceStorePadding.Count = result.Data.Count;

          this.OpenCompanysRelevanceStoreWindow = true;
        }
      );
    },

    //编辑页面
    EditOrAddWindowClose() {
      this.EditOrAddWindow = false;
    },
    // 保存
    EditOrAddSave() {
      const param = this.CompanysModel;
      axios.apiMethod(
        "/admins/Company/UpdateCompanys",
        "post",
        param,
        (result) => {
          if (result.Data.IsSuccess) {
            this.EditOrAddWindow = false;
            this.GetCompanyList();
          } else {
            ElMessageBox({
              title: "提示",
              message: result.Data.Msg,
              type: "error",
            });
          }
        }
      );
    },

    // 创建公司
    AddStore() {
      this.CompanysModel = {
        Email: "",
        Name: "",
        Phone: "",
        Password: "",
        Roles: [],
      };
      this.IsAdd = true;
      this.EditOrAddWindow = true;
    },
    // 编辑
    StoreEdit(index, row) {
      this.EditOrAddWindow = true;
      this.CompanysModel = row;
      this.IsAdd = false;
    },

    // 禁用
    StoreForbidden(index, row) {
      ElMessageBox.confirm("确定要禁用吗？")
        .then(() => {
          axios.apiMethod(
            "/store/StoreMaintain/Forbidden?Id=" + row.Id,
            "get",
            {},
            (result) => {
              if (result.Code == 200) {
                ElMessageBox({
                  title: "",
                  message: "操作成功",
                  type: "success",
                });
                this.GetStoreList();
              }
            }
          );
        })
        .catch(() => {
          // catch error
        });
    },
    // 取消禁用
    RemoveForbidden(index, row) {
      ElMessageBox.confirm("确定要取消禁用吗？")
        .then(() => {
          axios.apiMethod(
            "/store/StoreMaintain/RemoveForbidden?Id=" + row.Id,
            "get",
            {},
            (result) => {
              if (result.Code == 200) {
                ElMessageBox({
                  title: "",
                  message: "操作成功",
                  type: "success",
                });
                this.GetStoreList();
              }
            }
          );
        })
        .catch(() => {
          // catch error
        });
    },
    //刷新
    CurrentChange(index) {
      this.padding.Page = index;
      this.GetStoreList();
    },
    // 获取公司列表
    GetCompanyList() {
      this.padding.Where = {
        Name: "",
        Account: "",
      };
      this.padding.Search = this.SearchText;

      axios.apiMethod(
        "/admins/Company/GetCompanyList",
        "post",
        this.padding,
        (result) => {
          if (result.Code == 200) {
            this.CompanysDatas = result.Data.Datas;
            this.padding.Page = result.Data.Page;
            this.padding.Size = result.Data.Size;
            this.padding.Count = result.Data.Count;
          }
        }
      );
    },
  },
};
</script>

<style scoped></style>
